import React, { useState, useEffect, useRef } from "react";
import { useWallet } from '@txnlab/use-wallet';
import Transaction from "./Transaction";
import ConnectMenu from './connectMenu';
import "./styles.css";
import Modal from 'react-modal';
import algosdk from 'algosdk'

const algodClient = new algosdk.Algodv2(
  '', // Your Token Here
  'https://xna-mainnet-api.algonode.cloud', // Your BaseUrl Here
  '' // Your Port Here
);

function ShowAccount({ accountAddress }) {
  const shortAddress = accountAddress.slice(0, 8);
  return (
    <div className="wallet-address">
      <span>Wallet Address: </span>
      <span>{shortAddress}...</span>
    </div>
  );
}

function GameCard({ isConnected, onClick, game, setModalIsOpen, wallet }) {
  const { activeAccount, signTransactions, sendTransactions } = wallet; // Destructure methods here
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [sendingTransaction, setSendingTransaction] = useState(false);

  
  useEffect(() => {
    if (transactionSuccess) {
      const timer = setTimeout(() => {
        setTransactionSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [transactionSuccess]);

  const handleButtonClick = async (event) => {
    event.preventDefault();
    if (isConnected) {
      setSendingTransaction(true);
      try {
        const suggestedParams = await algodClient.getTransactionParams().do();
        console.log(wallet);
        console.log('To address: EF5CFMVEMFP4WYALTFM5IN5SVFEWT6ANH4DEDENISMC7TFCQ56B3FQDXRY');

        const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          from: activeAccount.address,
          to: 'EF5CFMVEMFP4WYALTFM5IN5SVFEWT6ANH4DEDENISMC7TFCQ56B3FQDXRY', // Replace with destination address
          amount: 1000, // Replace with transaction amount
          suggestedParams
        })

        // Create asset transfer transaction
        const assetTransferTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          from: activeAccount.address,
          to: 'EF5CFMVEMFP4WYALTFM5IN5SVFEWT6ANH4DEDENISMC7TFCQ56B3FQDXRY',
          amount: 5000,
          assetIndex: 433379445,
          suggestedParams
      })
  
      // Group transactions
      const txns = [transaction, assetTransferTxn]
      algosdk.assignGroupID(txns)

      const encodedTxns = txns.map(algosdk.encodeUnsignedTransaction);
        const signedTxns = await signTransactions(encodedTxns); // Correct usage
        const waitRoundsToConfirm = 4;
        const results = await sendTransactions(signedTxns, waitRoundsToConfirm); // Correct usage

          if (results && results.txId) {
            // Log the transaction ID and set transactionSuccess to true
            console.log('Successfully sent transaction. Transaction ID: ', results.txId);
            setTransactionSuccess(true);
          
            // Redirect to game link
            const encodedAddress = btoa(activeAccount.address);
            window.location.href = `${game.link}?data=${encodedAddress}`;
          }
      } catch (error) {
        console.error('Transaction failed:', error);
        setTransactionSuccess(false);
      } finally {
        setSendingTransaction(false);
      }
    } else {
      setModalIsOpen(true);
    }
  };

  return (
    <div className="card">
      <div className="box">
        <div className="content">
          <h2>{game.icon}</h2>
          <h3>{game.title}</h3>
          <p>{game.description}</p>
          <a
              href="#"
              onClick={handleButtonClick} // Assign the transaction to this button
          >
            Play Beta 5000 SUPER
          </a>
          {sendingTransaction && <div className="loader"></div>}
          {transactionSuccess && <div className="success-message">Transaction Successful✅</div>}
        </div>
      </div>
    </div>
  );
}

export default function App() {
  const wallet = useWallet();
  const { isActive, isReady, active } = wallet;
  const [showConnectMenu, setShowConnectMenu] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal
  const [iframeModalIsOpen, setIframeModalIsOpen] = useState(false);

  const handleConnectClick = () => {
    if (!isActive) {
      setShowConnectMenu(true);
      setModalIsOpen(false);
    } else {
      setShowConnectMenu(!showConnectMenu);
      setModalIsOpen(false);
    }
  };

  useEffect(() => {
    if (isReady && isActive) {
      setShowConnectMenu(false);
      setModalIsOpen(false);
    }
  }, [isReady, isActive])

  const games = [
    {
      icon: "(🪰)",
      title: "🪰Swarm Slayers🪰",
      description:
        "At the dark edges of the universe, swarms of galactic pests are plotting domination. A team of fighters has joined forces to protect the galaxy and exterminate the evil insects. They are the Swarm Slayers. (💻 or 📱)",
      link: "./games/Swarms/index.html",
  
    },
    {
      icon: "(🌮)",
      title: "🌮Taco Rush🌮",
      description:
        "It's raining Tacos, Nachos & burritos! Avoid the bombs and help Dora catch all the food in her truck to feed the hungry folks of Algoland. Submit your high score to the leaderboard. (💻 or 📱)",
      link: "./games/tacos/index.html",
    
    },
    {
      icon: "(💀)",
      title: "🌑Moon or Death💀",
      description:
        "Escape The planet of Gwarga and get to the kirneh Moon! Use the dune runner to collect the energy orbs and avoid crashing. Once you 			collect 10 orbs submit your score to the leaderboard. (💻 or 📱)",
      link: "./games/MOD/index.html",
    },
    {
      icon: "(🪽)",
      title: "🪽SuperFly🪽",
      description:
        "An addictive game where players guide SuperFly through the dense jungles of Algoland. Navigate your way through obstacles to climb the leaderboard and prove you are the ultimate SuperFly master. (💻 or 📱)",
      link: "./games/superfly/index.html",
    },
    // Add other games here
  ];

  return (
    <div className="app">
      <button
        className="button1"
        onClick={handleConnectClick}
      >
        {isActive ? "Disconnect" : "Connect Wallet"}
      </button>
      
      {showConnectMenu && <ConnectMenu />}
            
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIframeModalIsOpen(true);
        }}
        className="button2"
      >
        Get SUPER
      </a>


      <div className="container">
        {games.map((game) => (
          <GameCard
          key={game.title}
          isConnected={isActive}
          onClick={handleConnectClick}
          game={game}
          accountAddress={wallet.account}
          initiateTransaction={wallet.signTransaction}
          setModalIsOpen={setModalIsOpen}
          wallet={wallet}
        />
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="Modal" 
      >
        <div className="Modal-content"> 
          <h2>Please Connect Wallet First</h2>
          <button onClick={() => setModalIsOpen(false)}>close</button>
        </div>
      </Modal>
      <Modal
        isOpen={iframeModalIsOpen}
        onRequestClose={() => setIframeModalIsOpen(false)}
        className="IframeModal"
        contentLabel="Tinyman Swap Widget"
      >
        <iframe
          title="tinyman swap widget"
          src="https://hipo.github.io/ui098gh4350u9h435y-swap-widget/?platformName=SuperIO+Games&network=mainnet&themeVariables=eyJ0aGVtZSI6ImRhcmsiLCJjb250YWluZXJCdXR0b25CZyI6IiM0YTRmNGUiLCJ3aWRnZXRCZyI6IiM5Mjk1OWIiLCJoZWFkZXJCdXR0b25CZyI6IiNlN2UzZWUiLCJoZWFkZXJCdXR0b25UZXh0IjoiI2ZmZmZmZiIsImhlYWRlclRpdGxlIjoiI2ZmZmZmZiIsImNvbnRhaW5lckJ1dHRvblRleHQiOiIjZmZmZmZmIiwiaWZyYW1lQmciOiIjRjhGOEY4IiwiYm9yZGVyUmFkaXVzU2l6ZSI6Im1lZGl1bSIsInRpdGxlIjoiR2V0IFN1cGVyIFRva2VucyB0byBQbGF5Iiwic2hvdWxkRGlzcGxheVRpbnltYW5Mb2dvIjpmYWxzZX0%3D&assetIn=0&assetOut=433379445&platformFeeAccount=EF5CFMVEMFP4WYALTFM5IN5SVFEWT6ANH4DEDENISMC7TFCQ56B3FQDXRY&platformFeePercentage=3"
          style={{ display: "block", margin: "auto", width: "375px", height: "440px", border: "0", overflow: "hidden" }}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </Modal>
    </div>
  );
}

